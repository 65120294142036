import Link from "next/link";

export default function About() {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-c1145d7 elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="c1145d7"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-90c8b42"
          data-id="90c8b42"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-144a816 elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
              data-id="144a816"
              data-element_type="widget"
              data-widget_type="call-to-action.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-cta">
                  <div className="elementor-cta__bg-wrapper">
                    <div
                      className="elementor-cta__bg elementor-bg"
                      style={{
                        backgroundImage: "url(/images/about-us.webp)",
                      }}
                    ></div>
                    <div className="elementor-cta__bg-overlay"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-305aa6c"
          data-id="305aa6c"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-190cb7b elementor-invisible elementor-widget elementor-widget-heading"
              data-id="190cb7b"
              data-element_type="widget"
              data-settings='{"_animation":"fadeInUp"}'
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h5 className="elementor-heading-title elementor-size-default">
                  about us
                </h5>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-2e977be elementor-widget elementor-widget-heading"
              data-id="2e977be"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Complete & Customized ISO Solutions to Fit Every Need
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-ea63ad3 elementor-widget elementor-widget-text-editor"
              data-id="ea63ad3"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                We are a team of professionals who consistently put the
                client&apos;s needs before anything else. Expert consultants,
                simplified, customized, and automated ISO solutions, and a
                business upgrade is the only package we provide. Our client base
                is spread worldwide as we love to help businesses unlock their
                true potential, no matter the location, or size. We’ll help you
                upgrade your security practices and automate compliance with the
                industry’s most in-demand standards. Automated ISO process and
                consultancy, ISO certification services, security services,
                auditing, and training – we offer it all!
              </div>
            </div>
            <div
              className="elementor-element elementor-element-891dbdb elementor-tablet-align-center elementor-invisible elementor-widget elementor-widget-button"
              data-id="891dbdb"
              data-element_type="widget"
              data-settings='{"_animation":"fadeInDown"}'
              data-widget_type="button.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-button-wrapper">
                  <Link
                    rel="dofollow"
                    href="/about-us/"
                    title="About Us"
                    className="elementor-button-link elementor-button elementor-size-md"
                  >
                    <span className="elementor-button-content-wrapper">
                      <span className="elementor-button-text">Learn more</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
